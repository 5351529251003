import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {

    return (
    <Layout>
        <SEO title="Tenant App Information" />
        <h1 style={{display: 'grid', placeContent: 'center', marginTop: '15%', textAlign: 'center', fontSize: '3rem'}}>This is a page that will display information on Tenant App</h1>
    </Layout>
    )
}

export default IndexPage;